import styled from 'styled-components';
import * as React from 'react';
import { Offer, QuoteTypeEnum } from '@smartswap/client-api';
import { formatCurrency } from '@/lib/currency';
import { useTranslation } from 'react-i18next';

const Light = styled.span`
	color: #72809d;
`;

const QuoteWrapper = styled.div`
	font-family: InterUI, sans-serif;
	font-style: normal;
	font-weight: normal;
	text-align: center;
	margin-bottom: var(--step);
`;

export const Quote: React.FC<{ offer: Offer }> = ({ offer }) => {
	const { t } = useTranslation('checkout');
	const quotes = offer?.quotes?.filter((q) => q.type !== QuoteTypeEnum.Pickup); // TODO: Remove for pickup

	let text: string;

	if (!quotes) {
		text = 'No delivery options available';
	} else {
		text = t('delivery');

		const courierQuotes = quotes.filter((quote) => quote.type !== QuoteTypeEnum.Inperson);
		text += courierQuotes
			.map((quote) => {
				const courierName = t(`couriers.${quote.courierName || ''}`);
				return `${courierName} ${formatCurrency(quote.shipping || 0)} €`;
			})
			.join(', ');

		const inPerson = quotes.find((quote) => quote.type === QuoteTypeEnum.Inperson);

		if (inPerson) {
			if (courierQuotes.length) {
				text += t('orInPerson');
			} else {
				text = t('onlyInPerson');
			}
			text += ' 0 €';
		}
	}

	return (
		<QuoteWrapper>
			<Light>{text}</Light>
		</QuoteWrapper>
	);
};
