import { Link } from 'react-router-dom';
import { ReactComponent as LogoIcon } from '../Icons/Basic/Logo.svg';
import React from 'react';
import styled from 'styled-components';
import { screenSize } from '@/ScreenSize';
import footerItems from '../footerItems.json';
import { useTranslation } from 'react-i18next';

const Row = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  @media screen and ${screenSize.md} {
    flex-flow: row;
  }
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  grid-area: footer;
  background: #2c3f58;
  width: 100%;
  min-height: 80px;
  display: flex;
  flex-flow: column;
  color: #ffffff;
  font-family: InterUI;
  font-size: 14px;

  padding: 8px 8px;

  @media screen and ${screenSize.md} {
    padding: 64px 64px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  margin: 8px;

  &:not(:last-child) {
    margin: 8px;
  }

  @media screen and ${screenSize.sm} {
    &:not(:last-child) {
      margin-right: 64px;
    }
  }
`;

const FooterLink = styled.a`
  display: block;
  opacity: 0.5;

  :not(:first-child) {
    margin-top: 16px;
  }

  :hover {
    opacity: 1;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.4px;
`;

export const Footer = () => {
	const { t } = useTranslation('footer');

	return (
		<Wrapper>
			<Row>
				<Column style={{ flex: 1 }}>
					<Link to={'/'}>
						<LogoWrapper>
							<LogoIcon style={{ paddingTop: '10px' }}/>
							<div style={{ color: '#ffffff' }}>SmartSwap</div>
						</LogoWrapper>
					</Link>
				</Column>
				{footerItems.map((column, index) => (
					<Column key={index}>
						<Title>{t(column.title)}</Title>
						{column.items.map((item, index) => (
							<FooterLink key={index} href={item.href}>
								{t(item.title)}
							</FooterLink>
						))}
					</Column>
				))}
			</Row>

			<Row>
				<Column style={{ alignItems: 'center' }}>
					<a href={'https://kik.ee'}>
						<img src={'/images/kik_logo_h.png'} alt={'Keskkonnainvesteeringute keskus'}/>
					</a>
				</Column>
			</Row>
		</Wrapper>
	);
};
