import { useCallback, useMemo, useState } from 'react';
import { MarketApi, Offer, SearchGetRequest } from '@smartswap/client-api';
import { config } from '@/configuration';
import { useInfiniteQuery } from 'react-query';

const marketApi = new MarketApi(config);

export const useFeed = () => {
	const [category, setNewCategory] = useState<string>();
	const [query, setQuery] = useState<string>();
	const [warehouse, setWarehouse] = useState<string>();

	const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
		['offers', query, category, warehouse],
		({ pageParam }) => {
			const searchRequest: SearchGetRequest = {
				category,
				text: query,
				warehouse,
				archive: true,
			}

			if (pageParam) {
				searchRequest.cursor = pageParam;
			}

			return marketApi.searchGet(searchRequest);
		},
		{
			getNextPageParam: (lastPage) => lastPage.paging?.nextCursor,
		},
	);

	const setCategory = useCallback((newCategory: string) => {
		if (category !== newCategory) {
			setNewCategory(newCategory);
		}
	}, [category]);

	const offers = useMemo(() => data?.pages.reduce<Offer[]>((acc, current) => {
		return [...acc, ...(current.offers || [])];
	}, []) || [], [data?.pages]);

	return { offers, setQuery, setCategory, setWarehouse, loadMore: () =>fetchNextPage(), hasMore: hasNextPage }
};
