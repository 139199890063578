import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Card, CardContent, CardHeader } from '@/components/Card';
import { Button } from '@/components/Button';
import { InputField } from '@/components/Input';
import { Formik, FormikProps } from 'formik';
import { useNavigate } from 'react-router-dom';
import { login } from '@/services/Auth';
import * as Yup from 'yup';
import { AuthContext } from '@/contexts/AuthContext';
import { useTranslation } from 'react-i18next';

interface LoginFormProps {
	email: string;
	password: string;
}

const Wrapper = styled.div`
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	height: 100%;
`;

const LoginCard = styled(Card)`
	width: 340px;
`;

const LoginCardContent = styled(CardContent)`
	font-family: 'InterUI';
	font-weight: 500;
	font-size: 12px;
	padding-bottom: 20px;
`;

const ForgotPasswordLink = styled.a`
	width: 100%;
	display: block;
	text-align: center;
	margin-top: 1em;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
`;

const InputError = styled.div`
	margin: var(--step) 0;
	color: var(--color-error);
`;

const initialValues: LoginFormProps = {
	password: '',
	email: '',
};

const LoginForm = ({ handleSubmit }: FormikProps<LoginFormProps>) => {
	const { t } = useTranslation('auth');

	return (
		<form onSubmit={handleSubmit}>
			<InputField type={'email'} name={'email'} label={t('form.email')} />
			<InputField type={'password'} name={'password'} label={t('form.password')} />
			<Button $width={'full'} type={'submit'}>
				{t('form.login')}
			</Button>
		</form>
	);
};

export const LoginPage: React.FC = () => {
	const [failed, setFailed] = useState<boolean>(false);
	const auth = useContext(AuthContext);
	const { t } = useTranslation('auth');
	const navigate = useNavigate();

	const loginValidationSchema = Yup.object().shape({
		password: Yup.string().required(t('form.validation.passwordIsRequired')),
		email: Yup.string().required(t('form.validation.emailIsRequired')),
	});

	async function submitForm(values: LoginFormProps) {
		try {
			auth.login(await login(values.email, values.password));
			navigate('/offers', { replace: true });
		} catch (e) {
			setFailed(true);
		}
	}

	return (
		<Wrapper>
			<LoginCard>
				<CardHeader>{t('loginToAccount')}</CardHeader>
				<LoginCardContent>
					<Formik
						initialValues={initialValues}
						onSubmit={submitForm}
						component={LoginForm}
						validateOnChange={false}
						validateOnBlur={true}
						validationSchema={loginValidationSchema}
						failed={failed}
					/>
					{failed && <InputError>{t('invalidCredentials')}</InputError>}
					<ForgotPasswordLink onClick={() => navigate('/forgot', {replace: true})}>
						{t('forgotPassword', { postProcess: 'md' })}
					</ForgotPasswordLink>
				</LoginCardContent>
			</LoginCard>
		</Wrapper>
	);
};
