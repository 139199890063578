import { useTranslation } from 'react-i18next';
import { useDialog } from '@/contexts/DialogContext';
import { useNavigate } from 'react-router-dom';
import {
	MarketApi,
	Offer,
	OfferStateEnum,
	ShipmentStateEnum,
	TradeStateEnum,
	TradeTypeEnum,
} from '@smartswap/client-api';
import { getOfferChat, publishOffer, unpublishOffer } from '@/services/Offer';
import { CreateOfferDialog } from '@/components/CreateOfferDialog';
import { OfferThumbnail } from '@/components/Offer/OfferFeaturedImage';
import { Badge } from '@/components/Badge/Badge';
import { Button } from '@/components/Button';
import React from 'react';
import { config } from '@/configuration';
import styled from 'styled-components';
import { getUserId } from '@/services/UserService';
import { CloseIcon, EditIcon, EyeIcon, MessagesIcon, ThumbsDownIcon, ThumbsUpIcon } from '@/Icons';

const ListRow = styled.div`
	background: white;
	padding: 12px;
	margin-bottom: 16px;
	border-radius: 6px;

	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
	flex-wrap: wrap;
`;

const OfferItem = styled.div`
	display: flex;
	justify-content: space-between;
	flex-flow: column;
	flex: 1;
`;
const Title = styled.div`
	font-family: InterUI;
	font-weight: 500;
`;
const ActionsWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	flex: 1;
`;
const Stack = styled.div`
	display: flex;
	gap: var(--step);
`;

const ButtonIcon = {
	marginRight: 7,
	marginBottom: 2,
	height: 14,
	width: 14,
	verticalAlign: 'middle',
	opacity: 0.7,
};

interface GiveawayItemProps {
	offer: Offer;
	onItemChange?: (item: Offer) => void;
}

const getOfferActions = (offer: Offer) => {
	const amOwner = getUserId() === offer.owner?.id;

	const canEdit = amOwner && (offer.state === OfferStateEnum.Published || offer.state === OfferStateEnum.Draft);
	const canAccept =
		amOwner &&
		offer.state === OfferStateEnum.Reserved &&
		offer.trade?.type === TradeTypeEnum.Inperson &&
		offer.trade?.state === TradeStateEnum.Pending;
	const canReject = canAccept;
	const canComplete =
		!amOwner &&
		offer.state === OfferStateEnum.Reserved &&
		offer.trade?.type === TradeTypeEnum.Inperson &&
		offer.trade?.state === TradeStateEnum.Accepted;
	const canDelete = canEdit;
	const canChat = !(amOwner && (offer.state === OfferStateEnum.Published || offer.state === OfferStateEnum.Draft));
	const canUnpublish = amOwner && offer.state === OfferStateEnum.Published;
	const canPublish = amOwner && offer.state === OfferStateEnum.Draft;

	return {
		amOwner,
		canEdit,
		canDelete,
		canAccept,
		canReject,
		canComplete,
		canChat,
		canUnpublish,
		canPublish,
	};
};

const marketApi = new MarketApi(config);

export const GiveawayItem = ({ offer, onItemChange }: GiveawayItemProps) => {
	const { t } = useTranslation('my-items');
	const { showDialog } = useDialog();
	const navigate = useNavigate();

	const { amOwner, canEdit, canAccept, canChat, canReject, canComplete, canUnpublish, canPublish } =
		getOfferActions(offer);

	let attentionText = '';
	if (amOwner) {
		if (offer.state === OfferStateEnum.Reserved) {
			if (offer.trade?.state === TradeStateEnum.Pending) {
				attentionText = t('acceptOrReject');
			} else if (offer.trade?.shipment?.state === ShipmentStateEnum.Allocated) {
				attentionText = t(`takeTo`, { type: offer.trade?.type });
			} else if (offer.trade?.type === TradeTypeEnum.Inperson) {
				attentionText = t('arrangeInPerson');
			}
		}
	} else {
		if (offer.trade?.shipment?.state === ShipmentStateEnum.Collect) {
			attentionText = t('collectFrom', { type: offer.trade?.type });
		} else if (
			offer.trade?.type === TradeTypeEnum.Inperson &&
			offer.trade?.state === TradeStateEnum.Accepted &&
			offer.state === OfferStateEnum.Reserved
		) {
			attentionText = t('arrangeInPerson');
		}
	}

	let stateText = '';
	let stateColor: 'success' | 'neutral' = 'neutral';
	if (amOwner) {
		const name = offer.trade?.buyer.displayName || '???';

		switch (offer.state) {
			case OfferStateEnum.Reserved:
				switch (offer.trade?.shipment?.state) {
					case ShipmentStateEnum.Shipping:
						stateText = t('awaitingCollection', { name });
					// eslint-disable-next-line no-fallthrough
					case ShipmentStateEnum.Collect:
						stateText = t('shippingTo', { name });
						break;
					default:
						stateText = t('reservedBy', { name });
						break;
				}
				break;

			case OfferStateEnum.Traded:
				stateText = t('tradedTo', { name });
				stateColor = 'success';
				break;

			default:
				stateText = t(`offerStates.${offer.state}`);
				if (offer.state === OfferStateEnum.Published) {
					stateColor = 'neutral';
				}

				break;
		}
	} else {
		const name = offer.owner?.displayName;
		stateText = t('offeredBy', { name });
		stateColor = 'neutral';

		switch (offer.state) {
			case OfferStateEnum.Reserved:
				switch (offer.trade?.shipment?.state) {
					case ShipmentStateEnum.Allocated:
						stateText = t('awaitingShipment', { name });
						break;

					case ShipmentStateEnum.Shipping:
					case ShipmentStateEnum.Collect:
						stateText = t('onItsWay', { name });
						break;

					case undefined:
						if (offer.trade?.state === TradeStateEnum.Pending) {
							stateText = t('awaitingAcceptance', { name });
						} else {
							stateText = t('tradedBy', { name });
						}
						break;

					default:
						stateText = t('tradedBy', { name });
						break;
				}
				break;

			case OfferStateEnum.Traded:
				stateText = t('tradedBy', { name });
				stateColor = 'success';
				break;

			default:
				stateText = t('offeredBy', { name });
				break;
		}
	}

	async function unpublish() {
		await unpublishOffer(offer);
		onItemChange?.(offer);
	}

	async function publish() {
		await publishOffer(offer);
		onItemChange?.(offer);
	}

	function openEditDialog() {
		showDialog(<CreateOfferDialog offer={offer} onEdit={() => onItemChange?.(offer)} />);
	}

	async function acceptTrade() {
		await marketApi.offersIdTradeAcceptPost({ id: offer.id });
		onItemChange?.(offer);
	}

	async function rejectTrade() {
		await marketApi.offersIdTradeRejectPost({ id: offer.id });
		onItemChange?.(offer);
	}

	async function markTradeCompleted() {
		await marketApi.offersIdTradeCompletePost({ id: offer.id });
		onItemChange?.(offer);
	}

	async function openChat() {
		const chat = await getOfferChat(offer.id);
		navigate(`/messages/${chat.id}`, { state: { chat, offer } });
	}

	return (
		<ListRow>
			<div style={{ display: 'flex', gap: '16px', flex: 2 }}>
				<OfferThumbnail offer={offer} />
				<OfferItem>
					<Title>{offer.details.title}</Title>
					<Stack>
						{!!attentionText && <Badge $colorScheme={'primary'}>{attentionText}</Badge>}
						{!!stateText && <Badge $colorScheme={stateColor}>{stateText}</Badge>}
					</Stack>
				</OfferItem>
			</div>
			<ActionsWrapper>
				{canEdit && (
					<Button $color={'ghost'} onClick={openEditDialog}>
						<EditIcon style={ButtonIcon} />
						{t('edit')}
					</Button>
				)}
				{canUnpublish && (
					<Button $color={'ghost'} onClick={unpublish}>
						<CloseIcon style={ButtonIcon} />
						{t('unpublish')}
					</Button>
				)}
				{canPublish && (
					<Button $color={'ghost'} onClick={publish}>
						<EyeIcon style={ButtonIcon} />
						{t('publish')}
					</Button>
				)}
				{canAccept && (
					<Button $color={'ghost'} onClick={acceptTrade}>
						<ThumbsUpIcon style={ButtonIcon} />
						{t('accept')}
					</Button>
				)}
				{canReject && (
					<Button $color={'ghost'} onClick={rejectTrade}>
						<ThumbsDownIcon style={ButtonIcon} />
						{t('reject')}
					</Button>
				)}
				{canComplete && (
					<Button $color={'ghost'} onClick={markTradeCompleted}>
						<ThumbsUpIcon style={ButtonIcon} />
						{t('haveReceived')}
					</Button>
				)}
				{canChat && (
					<Button $color={'ghost'} onClick={openChat}>
						<MessagesIcon style={ButtonIcon} />
						{t('sendMessage')}
					</Button>
				)}
				{/*{canDelete && <Button $color={'ghost'}>{t('delete')}</Button>}*/}
			</ActionsWrapper>
		</ListRow>
	);
};
