import { createGlobalStyle } from 'styled-components';
import { screenSize } from '@/ScreenSize';
import { InputStyle } from '@/components/Input';

export const styleStep = 8;

export const GlobalStyle = createGlobalStyle`


	:root {
		--step: ${styleStep}px;
		--border-radius: 8px;
		--border-radius-small: 6px;
      
		--color-brand: #696CFF;
		--color-brand-pastel: #F2F2FF;
		--color-default: #FFFFFF;
		--color-primary: var(--color-brand);
		--color-primary-pastel: var(--color-brand-pastel);
		--color-success: #7ECC1A;
		--color-error: #F65F43;
		--color-danger: #F65F43;
		--color-info: #03C3EC;
		--color-warning: #FFD422;
		
		--color-text: #2C3F58;
		--color-placeholder: #b6bfcc;
		
		--color-gray-600: #35425B;
		--color-gray-500: #72809D;
		--color-gray-2: #F0F4F8;
		
		--color-chat-text: var(--color-gray-600);
		
	  	--badge-color-danger: var(--color-error);
		--badge-color-success: var(--color-success);
		--badge-color-neutral: var(--color-gray-500);
		--badge-color-primary: var(--color-brand);
	  
	  	--upvote-color-background: var(--color-default);
	  	--upvote-color-text: var(--color-gray-600);
	  	--upvote-border-radius: var(--border-radius-small);

		--upvote-active-color: var(--color-brand);
	  
		@media screen and ${screenSize.mobile} {
			--step: ${styleStep / 2}px;
		}
		
		--masonry-spacing: 20px;
		@media screen and ${screenSize.mobile} {
			--masonry-spacing: 10px;
		}
	}

    html, body {
        font-family: 'Poppins', sans-serif;
        background: rgba(250,251,253,1);
        color: #2C3F58;
        font-weight: 600;
        padding: 0;
        margin: 0;
        height: 100%;
	  	font-size: 16px;
        
    }
    
	*:focus {
		outline: none;
		//box-shadow: 0 0 0 1px var(--color-primary);
	}
	
    #root {
    	height: 100%;
    }
    
    a {
    	text-decoration: none;
    	color: inherit;
    }
    
    .masonry {
    	display: flex;
  		margin-left: calc(-1*var(--masonry-spacing)); /* gutter size offset */
  		width: auto;
    }

    .masonry-column {
    	padding-left: var(--masonry-spacing);
    	
    	& > * {
    		margin-bottom: var(--masonry-spacing);
    	}
    	
    	//--masonry-inner-border-radius: 2px;

    	//& > div {
    	//	&:not(:last-child) {
    	//		border-bottom-left-radius: var(--masonry-inner-border-radius);
    	//		border-bottom-right-radius: var(--masonry-inner-border-radius);
    	//	}
    	//	
    	//	&:not(:first-child) {
    	//		border-top-left-radius: var(--masonry-inner-border-radius);
    	//		border-top-right-radius: var(--masonry-inner-border-radius);
    	//	}
    	//}
    	
    	//&:not(:last-child) > div {
    	//	border-top-right-radius: var(--masonry-inner-border-radius);
    	//	border-bottom-right-radius: var(--masonry-inner-border-radius);
    	//}
    	
    	//&:not(:first-child) > div {
    	//	border-top-left-radius: var(--masonry-inner-border-radius);
    	//	border-bottom-left-radius: var(--masonry-inner-border-radius);
    	//}
    }
    
    .react-tel-input {
    	&& {
			input {
				${InputStyle};
				padding-left: 48px;
			}
			
			.flag-dropdown {
				border-radius: 5px 0 0 5px;
				border: none;
				background: none;
				
				&.open .selected-flag {
					background: none;
				}
			}
    	}
    }
`;

export const shadow = (level: number) => {
	return [
		`box-shadow: -2px 2px 5px rgba(67, 86, 100, 0.123689), -1px 1px 2px rgba(67, 70, 74, 0.0001);`,
		`box-shadow: -4px 5px 8px rgba(67, 86, 100, 0.123689), -1px 2px 4px rgba(67, 86, 100, 0.10047);`,
		`box-shadow: -8px 8px 16px rgba(67, 86, 100, 0.0780977), -4px 4px 8px rgba(67, 86, 100, 0.102956);`,
		`box-shadow: -16px 16px 32px rgba(67, 86, 100, 0.165947);`,
		`box-shadow: 0px 40px 84px rgba(67, 86, 100, 0.138604);`,
	][level];
};

// export interface ColorPalette {
// 	[key: string]: FlattenSimpleInterpolation;
// }

// export const colorPalette: ColorPalette = {
// 	primary: css`var(--color-primary);`,
// 	default: css`var(--color-default);`,
// 	success: css`var(--color-success);`,
// 	error: css`var(--color-error);`,
// 	info: css`var(--color-info);`,
// 	warning: css`var(--color-warning);`,
//
// 	'primary-pastel': css`var(--color-primary-pastel);`
// };
