import { MarketApi, Offer } from '@smartswap/client-api';
import styled, { css } from 'styled-components';
import { UpTriangle } from '@/Icons';
import { useCallback, useState } from 'react';
import { config } from '@/configuration';
import { getUserId } from '@/services/UserService';

interface ButtonProps {
	$active?: boolean;
}

const Button = styled.button<ButtonProps>`
	min-width: 32px;
	min-height: 32px;
	background: var(--upvote-color-background);
	border: none;
	position: absolute;
	right: 8px;
	bottom: 8px;
	display: flex;
	flex-flow: column;
	align-items: center;
	border-radius: var(--border-radius-small);
	padding: 4px;
	font-family: InterUI, sans-serif;
	justify-content: space-between;
	line-height: 1;

	color: var(--upvote-color-text);
	font-size: 12px;

	&:enabled {
		cursor: pointer;

		&:hover {
			color: var(--upvote-active-color);
			box-shadow: 0 0 0 2px var(--upvote-active-color);

			svg {
				fill: var(--upvote-active-color);
			}
		}
	}

	svg {
		fill: #aeb7c9;
	}

	${(p) =>
		p.$active &&
		css`
			color: var(--upvote-active-color);

			svg {
				fill: var(--upvote-active-color);
			}
		`}
`;

interface UpvoteButtonProps {
	offer: Offer;
}

const marketApi = new MarketApi(config);

export function UpvoteButton({ offer }: UpvoteButtonProps) {
	const userId = getUserId();
	const [upvoteValue, setUpvoteValue] = useState<number>(offer.details.upvote || 0);
	const [hasUpVoted, setHasUpVoted] = useState<boolean>(!!offer.upvotedByUser);

	const handleUpvote = useCallback(
		async (ev: React.MouseEvent) => {
			ev.preventDefault();

			if (hasUpVoted) {
				setHasUpVoted(false);
				setUpvoteValue((prev) => prev - 1);
				await marketApi.offersIdDownvotePut({ id: offer.id });
			} else {
				setHasUpVoted(true);
				setUpvoteValue((prev) => prev + 1);
				await marketApi.offersIdUpvotePut({ id: offer.id });
			}
		},
		[hasUpVoted, offer],
	);

	return (
		<Button disabled={!userId || offer.owner?.id === userId} onClick={handleUpvote} $active={hasUpVoted}>
			<UpTriangle />
			<span>{upvoteValue}</span>
		</Button>
	);
}
