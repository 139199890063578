import React from 'react';
import { Collapsible } from '@/components/Collapsible';
import { VerticalFlow } from './HomePage';
import styled from 'styled-components';
import { ContentArea } from './ContentPage';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
`;

export const FaqPage: React.FC = () => {
	const { t } = useTranslation('faq');

	// @ts-ignore
	const faqItems: { title: string; content: string }[] = t('items', { returnObjects: true }) || [];

	return (
		<Wrapper>
			<ContentArea>
				<h1>{t('title')}</h1>
				<VerticalFlow style={{ maxWidth: '762px', marginTop: '32px' }}>
					{faqItems.map((faq) => (
						<Collapsible key={faq.title} title={faq.title}>
							{faq.content}
						</Collapsible>
					))}
				</VerticalFlow>
			</ContentArea>
		</Wrapper>
	);
};
