import styled, { css } from 'styled-components';
import { Field, useField } from 'formik';
import React, { SelectHTMLAttributes, TextareaHTMLAttributes } from 'react';

export const InputStyle = css`
	font-size: 14px;
	color: rgba(119, 134, 153, 1);
	background: rgba(240, 244, 248, 1);
	border-radius: 5px;
	outline: none;
	font-family: 'InterUI';
	border: 1px solid rgba(229, 235, 241, 1);
	padding: 7px 12px;
	width: 100%;
	font-weight: normal;
	box-sizing: border-box;
	-webkit-appearance: none;

	:focus {
		box-shadow: 0 0 0 1px var(--color-primary);
	}

	::placeholder {
		color: var(--color-placeholder);
	}
`;

const InvalidInputStyle = css`
	border-color: var(--color-error);
`;

interface InvalidInputProps {
	$invalid?: boolean;
}

export const Input = styled.input<InvalidInputProps>`
	${InputStyle};

	${(p) => p.$invalid && InvalidInputStyle}
`;

export const CheckBoxInput = styled.input<InvalidInputProps>`
	${InputStyle};
	-webkit-appearance: checkbox;
	width: initial;

	${(p) => p.$invalid && InvalidInputStyle}
`;

export const Select = styled.select<InvalidInputProps>`
	${InputStyle};
	height: 34px;

	${(p) => p.$invalid && InvalidInputStyle}
`;

export const TextArea = styled.textarea<InvalidInputProps>`
	${InputStyle};

	${(p) => p.$invalid && InvalidInputStyle}
`;

export const StyledField = styled(Field)`
	${InputStyle};
`;

const Label = styled.label`
	display: block;
	margin: 5px 0 7px;
	font-size: 12px;

	& > div {
		margin: 7px 0;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

const InputError = styled.div`
	color: var(--color-error);
`;

interface InputFieldProps {
	type?: string;
	name: string;
	label?: string;
	placeholder?: string;
}

export const InputField: React.FC<InputFieldProps> = (props) => {
	const [field, meta] = useField(props.name);

	return (
		<>
			<Label>
				{props.label && <div>{props.label}</div>}
				<Input
					{...field}
					type={props.type || 'text'}
					$invalid={!!meta.error && meta.touched}
					placeholder={props.placeholder}
				/>
				{!!meta.error && meta.touched && <InputError>{meta.error}</InputError>}
			</Label>
		</>
	);
};

interface SelectFieldProps {
	name: string;
	label?: string;
}

export const SelectField: React.FC<SelectFieldProps & SelectHTMLAttributes<HTMLSelectElement>> = ({
	name,
	label,
	children,
	...props
}) => {
	const [field, meta] = useField(name);

	return (
		<>
			<Label>
				{label && <div>{label}</div>}
				<Select {...field} {...props} $invalid={!!meta.error && meta.touched}>
					{children}
				</Select>
				{!!meta.error && meta.touched && <InputError>{meta.error}</InputError>}
			</Label>
		</>
	);
};

export const TextAreaField: React.FC<SelectFieldProps & TextareaHTMLAttributes<HTMLTextAreaElement>> = ({
	name,
	label,
	children,
	...props
}) => {
	const [field, meta] = useField(name);

	return (
		<>
			<Label>
				<div>{label}</div>
				<TextArea {...field} {...props} $invalid={!!meta.error && meta.touched} />
				{!!meta.error && meta.touched && <InputError>{meta.error}</InputError>}
			</Label>
		</>
	);
};

export const CheckboxField: React.FC<InputFieldProps> = (props) => {
	const [field, meta] = useField(props.name);

	return (
		<>
			<Label>
				{props.label && <div>{props.label}</div>}
				<CheckBoxInput
					{...field}
					type={'checkbox'}
					$invalid={!!meta.error && meta.touched}
					placeholder={props.placeholder}
				/>
				{!!meta.error && meta.touched && <InputError>{meta.error}</InputError>}
			</Label>
		</>
	);
};

export const CheckboxGroup = styled.div`
	${Label} {
		display: flex;
		flex-flow: row-reverse;
		align-items: center;
		justify-content: flex-end;
	}

	${CheckBoxInput} {
		margin-right: 12px;
	}
`;
