import * as React from 'react';
import { useCallback, useContext, useState } from 'react';
import { config } from '@/configuration';
import { Card as PaymentCard, Country, LookupApi, PaymentApi, PersonalApi, Profile } from '@smartswap/client-api';
import { Card, CardContent } from '@/components/Card';
import styled from 'styled-components';
import { Button } from '@/components/Button';
import { screenSize } from '@/ScreenSize';
import { UpdatePhoneDialog } from '@/components/UpdatePhoneDialog';
import { DialogContext } from '@/contexts/DialogContext';
import { AddCardDialog } from '@/components/AddCardDialog';
import useAsyncEffect from 'use-async-effect';
import { useLocation } from 'react-router-dom';
import { SelfContainedInput, SelfContainedSelect } from '@/components/SelfContainedInput';
import { PaymentMethod } from '@/components/ProfilePage/PaymentMethod';
import { UpdateEmailDialog } from '@/components/UpdateEmailDialog';
import { useTranslation } from 'react-i18next';
import { usePersonalAddresses } from '@/hooks/api/usePersonalAddresses';
import { AddressRow } from '@/components/Address/AddressRow';
import { AddAddressDialog } from '@/components/Address/AddAddressDialog';
import { MemberContext } from '@/contexts/MemberContext';

const lookupApi = new LookupApi(config);
const personalApi = new PersonalApi(config);
const paymentApi = new PaymentApi(config);

const GridWrapper = styled.div``;

const Grid = styled.div`
	display: grid;
	grid-gap: 24px;
	font-family: 'InterUI';
`;

const Section = styled(Card)`
	width: 100%;
`;

const InnerCardContent = styled(CardContent)`
	padding: 20px;
`;

const Title = styled.h3``;

const Buttons = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;

	${Button} {
		margin-left: 1em;
	}
`;

const Form = styled.div`
	display: grid;
	grid-row-gap: 1em;
`;

const Personal = styled(InnerCardContent)`
	display: grid;
	grid-template-columns: 0.3fr 0.7fr;
	grid-template-areas:
		'title buttons'
		'avatar form';

	@media screen and ${screenSize.mobile} {
		grid-template-columns: 1fr;
		grid-template-areas:
			'title'
			'buttons'
			'avatar'
			'form';
	}

	> ${Title} {
		grid-area: title;
	}

	> ${Buttons} {
		grid-area: buttons;
	}

	> ${Form} {
		grid-area: form;
	}
`;

const Avatar = styled.div`
	grid-area: avatar;
`;

export const ProfilePage: React.FC = () => {
	const { update: updateMemberContext } = useContext(MemberContext);
	const [profile, setProfile] = useState<Profile | null>(null);
	const [countries, setCountries] = useState<Country[]>([]);
	const { showDialog, popDialog } = useContext(DialogContext);
	const [cards, setCards] = useState<PaymentCard[]>([]);
	const query = new URLSearchParams(useLocation().search);
	const { t } = useTranslation('profile');
	const { addresses, update: updateAddress } = usePersonalAddresses();

	useAsyncEffect(async () => {
		const code = query.get('confirm-email');
		if (code) {
			await personalApi.myEmailConfirmPost({
				confirmEmailRequest: {
					code: code,
				},
			});
		}
	}, []);

	async function updateProfile() {
		const data = await personalApi.myProfileGet();
		setProfile(data);
		updateMemberContext();
	}

	const updateCards = useCallback(async () => {
		const cardData = await paymentApi.myCardsGet();
		setCards(cardData?.cards || []);
		updateMemberContext();
	}, [updateMemberContext]);

	useAsyncEffect(async () => {
		const countryData = await lookupApi.lookupCountriesGet();
		setCountries(countryData?.countries || []);

		await updateProfile();
	}, []);

	useAsyncEffect(updateCards, []);

	async function updateFullName(value: string) {
		await personalApi.myFullNamePut({ updateFullNameRequest: { fullName: value } });
		await updateProfile();
	}

	async function updateCountry(value: string) {
		await personalApi.myCountryPut({ updateCountryRequest: { countryCode: value } });
		await updateProfile();
	}

	async function updateEmail(value: string) {
		await personalApi.myEmailPut({ updateEmailRequest: { newEmail: value } });
		showDialog(<UpdateEmailDialog email={value} />);
	}

	async function showAddAddressDialog() {
		const onComplete = () => {
			popDialog();
			updateAddress();
		};

		showDialog(<AddAddressDialog onComplete={onComplete} />);
	}

	async function resendEmailVerification() {
		await personalApi.myEmailResendPost();
	}

	const handleCardAdded = useCallback(() => {
		popDialog();
		updateCards();
	}, [popDialog, updateCards]);

	const onAddCard = useCallback(() => {
		showDialog(<AddCardDialog onComplete={handleCardAdded} />);
	}, [handleCardAdded, showDialog]);

	return (
		<GridWrapper>
			<h2>{t('accountSettings')}</h2>
			{profile && (
				<Grid>
					<Section>
						<Personal>
							<Title>{t('personal')}</Title>
							<Buttons />
							<Avatar />
							<Form>
								<SelfContainedInput
									label={t('fullName')}
									name="full_name"
									initialValue={profile.fullName}
									onSubmit={updateFullName}
								/>
								<SelfContainedInput
									label={t('displayName')}
									name="display_name"
									initialValue={profile.displayName}
									readonly={true}
								/>
								<SelfContainedSelect
									label={t('country')}
									name={'country_code'}
									initialValue={profile.country}
									onSubmit={updateCountry}
								>
									<option value="" disabled={true}>
										{t('selectCountry')}
									</option>
									{countries.map((country) => (
										<option key={country.code} value={country.code}>
											{country.name}
										</option>
									))}
								</SelfContainedSelect>
							</Form>
						</Personal>
					</Section>
					<Section style={{ overflow: 'visible' }}>
						<InnerCardContent style={{ borderRadius: 'var(--border-radius)' }}>
							<h3>{t('contact')}</h3>
							<Form>
								<SelfContainedInput
									label={t('email')}
									name="email"
									initialValue={profile.email}
									onSubmit={updateEmail}
								/>
								{!profile?.flags?.isEmailVerified && (
									<div>
										<Button onClick={resendEmailVerification}>{t('resendVerification')}</Button>
									</div>
								)}
								<SelfContainedInput
									label={t('mobilePhone')}
									type={'phone'}
									name="phone"
									initialValue={profile.phone}
									onSubmit={async (value) => {
										await personalApi.myPhonePut({
											updatePhoneRequest: {
												newPhone: value,
											},
										});

										showDialog(
											<UpdatePhoneDialog number={value} onSubmit={() => updateProfile()} />,
										);
									}}
								/>
							</Form>
						</InnerCardContent>
					</Section>
					<Section>
						<InnerCardContent>
							<h3>{t('addresses')}</h3>
							{addresses.map((address) => (
								<AddressRow key={address.id} address={address} onRemove={updateAddress} />
							))}
							<Form>
								<Button type={'button'} onClick={showAddAddressDialog}>
									{t('addAddress')}
								</Button>
							</Form>
						</InnerCardContent>
					</Section>
					<Section>
						<InnerCardContent>
							<h3>{t('paymentMethods')}</h3>
							{cards.map((card) => (
								<PaymentMethod card={card} key={card.id} onRemove={updateCards} />
							))}
							<Form>
								<Button type={'button'} onClick={onAddCard}>
									{t('addCard')}
								</Button>
							</Form>
						</InnerCardContent>
					</Section>
				</Grid>
			)}
		</GridWrapper>
	);
};
