import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { AddIcon } from '@/Icons';
import { ImageTile, ImageTiles } from './ImageTiles';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
	display: flex;
	flex-flow: row;

	> :not(:first-child) {
		margin-left: 16px;
	}
`;

const AddImageTile = styled(ImageTile)`
	border: 1px solid #dce1e7;
	font-family: 'InterUI';
	display: flex;
	flex-flow: column;
	justify-content: space-evenly;
	align-items: center;
	padding: 18px 0;
`;

export interface ImageRemoved {
	image: Blob;
	indexRemoved: number;
	remainingImages: Blob[];
}

interface ImageUploadProps {
	maxImages?: number;
	onAdd?: (image: Blob, images: Blob[]) => void;
	onRemove?: (image: ImageRemoved) => void;
	initialBlobs?: Blob[];
}

export const ImageUpload: React.FC<ImageUploadProps> = ({ onAdd, onRemove, maxImages = 3, initialBlobs }) => {
	const [files, setFiles] = useState<Blob[]>(initialBlobs || []);
	const imageInputRef = useRef<HTMLInputElement>(null);
	const { t } = useTranslation();

	useEffect(() => {
		if (initialBlobs) {
			setFiles(initialBlobs);
		}
	}, [initialBlobs]);

	function onFileChange(ev: ChangeEvent<HTMLInputElement>) {
		const targetFiles = ev.target.files;

		if (targetFiles && targetFiles.length > 0) {
			const currItem = targetFiles[0];

			if (currItem !== null) {
				const newFiles = [...files, currItem];
				setFiles(newFiles);
				onAdd?.(currItem, newFiles);
			}
		}
		ev.target.value = '';
	}

	function addImage() {
		if (!imageInputRef.current) return;
		imageInputRef.current.click();
	}

	const removeImage = useCallback((removeFile: Blob) => {
		const indexRemoved = files.indexOf(removeFile);
		const remainingImages = files.filter((file, i) => i !== indexRemoved); // splice will mess with rendering
		setFiles(remainingImages);
		onRemove?.({ image: removeFile, remainingImages, indexRemoved });
	}, [files, setFiles, onRemove]);

	return (
		<Wrapper>
			<ImageTiles images={files} onRemove={removeImage} />

			{files.length < maxImages && (
				<>
					<AddImageTile onClick={addImage}>
						<AddIcon />
						{t('photo', { count: maxImages - files.length })}
					</AddImageTile>
					<input
						style={{ position: 'absolute', opacity: 0, width: '1px', height: '1px', zIndex: -9000 }}
						ref={imageInputRef}
						name={'imageFile'}
						type={'file'}
						accept={'image/*;capture=camera'}
						onChange={onFileChange}
					/>
				</>
			)}
		</Wrapper>
	);
};
