import React, { PropsWithChildren, useCallback } from 'react';
import '@/styles/GlobalStyles.css';
import { GlobalStyle } from '@/styles/GlobalStyle';
import { LoginPage } from '@/pages/LoginPage';
import { FeedPage } from '@/pages/FeedPage';
import { Layout } from '@/components/Layout';
import { ItemPage } from '@/pages/ItemPage';
import { RegisterPage } from '@/pages/RegisterPage';
import { ProfilePage } from '@/pages/ProfilePage';
import { GiveawayItemsPage } from '@/pages/GiveawayItemsPage';
import { ForgotPasswordPage } from '@/pages/ForgotPasswordPage';
import { VerifyEmailPage } from '@/pages/VerifyEmailPage';
import { MessageView } from '@/components/DirectMessages/MessageView';
import { UserList } from '@/components/DirectMessages/UserList';
import { screenSize } from '@/ScreenSize';
import { ContentPage } from '@/pages/ContentPage';
import { Outlet, Route, Routes } from 'react-router-dom';
import { HomePage } from '@/pages/HomePage';
import { FaqPage } from '@/pages/FaqPage';
import { LoadFeatures } from '@/contexts/FeaturesContext';
import { Helmet } from 'react-helmet';
import { useUrlLocaleSwitcher } from '@/hooks/useUrlLocaleSwitcher';
import { useTranslation } from 'react-i18next';
import { AppProviders } from '@/components/Providers/AppProviders';
import { InternalProviders } from '@/components/Providers/InternalProviders';
import { CampaignPage } from '@/pages/CampaignPage';
import { useMediaQuery } from '@/hooks/useMediaQuery';

// const ShowOnSm = styled.div`
// 	@media screen and ${screenSize.sm} {
// 		display: none;
// 	}
// `;

const MountOnSm = ({children}: PropsWithChildren) => {
	const isDesk = useMediaQuery(`screen and ${screenSize.sm}`);
	if (isDesk) return null;
	return <div>{children}</div>;
}


const App: React.FC = () => {
	const { i18n } = useTranslation();
	const changeLanguage = useCallback((lang: string) => i18n.changeLanguage(lang), [i18n]);
	useUrlLocaleSwitcher(changeLanguage);

	return (
		<AppProviders>
			<Helmet defaultTitle="SmartSwap" titleTemplate="%s - SmartSwap">
				<meta name="description" content="Old is the new new" />
			</Helmet>
			<LoadFeatures />
			<GlobalStyle />
			<Routes>
				<Route path="/login" element={<LoginPage />} />
				<Route path="/register" element={<RegisterPage />} />
				<Route path="/forgot/:code" element={<ForgotPasswordPage />} />
				<Route path="/forgot" element={<ForgotPasswordPage />} />
				<Route path="/verify/email/:code" element={<VerifyEmailPage />} />

				<Route path="/*" element={<InternalLayout />}>
					<Route path={'messages/:chatId'} element={<MessageView />} />

					<Route
						path={'messages'}
						element={
							<MountOnSm>
								<UserList />
							</MountOnSm>
						}
					></Route>
					<Route path={'c/faq'} element={<FaqPage />} />
					<Route path={'c/what-is-smartswap'} element={<HomePage />} />
					<Route path={'c/:slug'} element={<ContentPage />} />
					<Route path={'item/:itemId'} element={<ItemPage />} />
					<Route path={'profile/settings'} element={<ProfilePage />} />
					<Route path={'profile/giveaway'} element={<GiveawayItemsPage />} />
					<Route path={'minimalism'} element={<CampaignPage />} />

					<Route path={'trades'} element={<FeedPage defaultCategory={'_archive'} />} />
					<Route path={'offers/category/:categorySlug'} element={<FeedPage />} />
					<Route path={'offers/:warehouse'} element={<FeedPage />} />
					<Route path={'offers'} element={<FeedPage />} />
					<Route path={''} element={<FeedPage />} />
				</Route>
			</Routes>
		</AppProviders>
	);
};

export default App;

const InternalLayout = () => {
	return (
		<InternalProviders>
			<Layout>
				<Outlet />
			</Layout>
		</InternalProviders>
	);
};
