import styled from 'styled-components';
import { Card } from './Card';
import { screenSize } from '@/ScreenSize';
import React, { CSSProperties, PropsWithChildren, useContext } from 'react';
import { CloseIcon } from '../Icons';
import { Button } from './Button';
import { DialogContext } from '@/contexts/DialogContext';

const Background = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background: rgba(114, 128, 157, 0.5);
	display: flex;

	justify-content: flex-start;
	align-items: flex-start;

	z-index: 100;

	@media screen and ${screenSize.sm} {
		justify-content: center;
		align-items: center;
	}
`;

const InnerCard = styled(Card)`
	overflow: initial;
	max-width: 480px;
	max-height: 100%;
	overflow-y: visible;
	padding: 16px 24px 24px;
	width: 100%;

	@media screen and ${screenSize.mobile} {
		overflow-y: auto;
		width: 100%;
		padding: 16px;
	}
`;

const CloseButton = styled(Button)`
	background: transparent;
`;

const Header = styled.div`
	position: relative;
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	margin-bottom: 8px;
`;

const Title = styled.div`
	display: flex;
	align-items: center;
`;

const Content = styled.div`
	font-family: InterUI;
`;

interface DialogProps {
	overflow?: boolean;
	className?: string;
	style?: CSSProperties;
	onClick?: (ev: React.MouseEvent) => void;
	canClose?: boolean;
	title?: string;
}

export const Dialog: React.FC<PropsWithChildren<DialogProps>> = ({ children, title, canClose = true, overflow, ...divProps }) => {
	const dialogContext = useContext(DialogContext);

	return (
		<Background {...divProps}>
			<InnerCard style={{ overflowY: overflow ? 'visible' : 'auto' }}>
				<Header>
					<Title>{title}</Title>
					<CloseButton onClick={dialogContext.popDialog}>
						<CloseIcon />
					</CloseButton>
				</Header>
				<Content>{children}</Content>
			</InnerCard>
		</Background>
	);
};
