import React from 'react';
import moment from 'moment';
import styled, { css } from 'styled-components';
import { Card } from '@/components/Card';

export interface MessageAuthor {
	$isMy?: boolean;
}

interface MessageTimeProps {
	className?: string;
	$locale?: string;
	dateTime: Date;
}

const MessageTime: React.FC<MessageTimeProps> = ({ dateTime, className }) => {
	const momentDate = moment(dateTime);
	const momentString =
		moment.duration(moment().diff(momentDate)).asDays() < 1 ? momentDate.fromNow() : momentDate.format('L LT');

	return <div className={className}>{momentString}</div>;
};

export const StyledMessageTime = styled(MessageTime)<MessageAuthor>`
	margin-top: 8px;
	font-size: 12px;
	color: #778699;

	${(p) =>
		p.$isMy &&
		css`
			align-self: flex-end;
		`}
`;

export const MessageBubble = styled(Card)<MessageAuthor>`
	padding: 16px 13px;
	margin: 16px 0 0;
	max-width: 90%;
	box-sizing: border-box;
	overflow: initial;
	width: fit-content;

	color: var(--color-chat-text);

	${(p) =>
		p.$isMy &&
		css`
			align-self: flex-end;
		`}
`;
