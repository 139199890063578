import styled from 'styled-components';
import { shadow } from '@/styles/GlobalStyle';

export const Card = styled.div`
	width: max-content;
	border-radius: var(--border-radius);
	${shadow(1)};
	background: rgba(255, 255, 255, 1);
	overflow: hidden;
	box-sizing: border-box;
`;

export const CardHeader = styled.div`
	padding: calc(var(--step) * 4) calc(var(--step) * 4) var(--step);
	color: rgba(44, 63, 88, 1);
	font-size: 20px;
	font-weight: 600;
	letter-spacing: -0.4px;

	border-top-left-radius: var(--border-radius);
	border-top-right-radius: var(--border-radius);

	display: flex;
	align-items: center;
	justify-content: center;
`;

export const CardContent = styled.div`
	background: rgba(255, 255, 255, 1);
	padding: var(--step) calc(var(--step) * 4);
`;

export const CardFooter = styled.div`
	padding: var(--step) calc(var(--step) * 4) calc(var(--step) * 4);
	border-top: 1px solid rgba(215, 225, 234, 1);
	background: rgba(250, 251, 253, 1);

	border-bottom-left-radius: var(--border-radius);
	border-bottom-right-radius: var(--border-radius);
`;
