import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GridView } from '@/components/GridView';
import { GridGroup, GridHeader } from '@/components/GridGroup';
import { OffersGrid } from '@/components/OffersGrid';
import { Button } from '@/components/Button';
import styled from 'styled-components';
import { useQuery } from '@/hooks/useQuery';
import InfiniteScroll from 'react-infinite-scroller';
import { useFeed } from '@/hooks/useFeed';
import { FriendInviteCard } from '@/components/FriendInviteCard';
import { useTranslation } from 'react-i18next';

const LoadMoreButton = styled(Button)`
	margin-top: 32px;
	width: 100%;
`;

interface FeedPageProps {
	defaultCategory?: string;
}

export const FeedPage: React.FC<FeedPageProps> = ({ defaultCategory }) => {
	const { offers, loadMore, setCategory, hasMore, setQuery, setWarehouse } = useFeed();
	const { categorySlug = defaultCategory, warehouse } = useParams();
	const query = useQuery();
	const searchQuery = query.get('search') || null;
	const [autoLoad, setAutoLoad] = useState<boolean>(false);
	const { t } = useTranslation(['common', 'categories', 'minimalism']);

	useEffect(() => {
		setCategory(categorySlug || '');
		setWarehouse(warehouse);
		setAutoLoad(false);
	}, [categorySlug, setCategory, setWarehouse, warehouse]);

	useEffect(() => {
		setQuery(searchQuery || '');
	}, [searchQuery, setQuery]);

	function startAutoload() {
		setAutoLoad(true);
	}

	let header = t(`categories:${categorySlug || warehouse || 'allCategories'}`);

	if (searchQuery) {
		header = t('resultsFor', { searchQuery });
	}

	return (
		<GridView>
			<GridGroup>
				<GridHeader>{header}</GridHeader>

				<InfiniteScroll loadMore={loadMore} hasMore={autoLoad && hasMore}>
					<OffersGrid offers={offers} viewOnly={categorySlug === '_archive'}>
						<FriendInviteCard />
					</OffersGrid>
				</InfiniteScroll>
				{hasMore && !autoLoad && (
					<LoadMoreButton onClick={startAutoload}>{t('loadMoreItems', { ns: 'common' })}</LoadMoreButton>
				)}
			</GridGroup>
		</GridView>
	);
};
