import React, { CSSProperties, useEffect, useState } from 'react';
import { searchOffers } from '@/services/MarketService';
import { Offer } from '@smartswap/client-api';
import { OfferCard } from './OffersGrid';
import 'swiper/css';
import styled from 'styled-components';
import { Swiper } from 'swiper/react';

const SwiperWrapper = styled.div`
	position: relative;
	width: 100%;

	.swiper-container {
		overflow: visible;
	}
`;

interface OffersCarouselProps {
	className?: string;
	style?: CSSProperties;
}

export const OffersCarousel: React.FC<OffersCarouselProps> = ({ className, style }) => {
	const [offers, setOffers] = useState<Offer[]>();

	useEffect(() => {
		searchOffers().then((r) => setOffers(r.offers));
	}, []);

	if (!offers) {
		return null;
	}

	// @ts-ignore
	const swiperProps: ReactIdSwiperCustomProps = {
		freeMode: true,
		spaceBetween: 24,
		slidesPerView: 'auto',
	};

	return (
		<SwiperWrapper className={className} style={style}>
			<Swiper {...swiperProps}>
				{offers.map((offer) => {
					// return <div key={offer.id}>{offer.details.title}</div>;
					return <OfferCard offer={offer} key={offer.id} />;
				})}
			</Swiper>
		</SwiperWrapper>
	);
};
