import { Chat } from '@smartswap/client-api';

export const getParticipantNames = (chat: Chat | null, excludeId: string | null = null, separator: string = ' ') => {
	if (!chat?.participants || !excludeId) return null;

	const names: string[] = [];

	chat.participants.forEach((participant) => {
		if (participant.id !== excludeId) {
			names.push(participant.displayName);
		}
	});

	return names.join(separator);
};
