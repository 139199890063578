import * as React from 'react';
import styled from 'styled-components';
import { PropsWithChildren } from 'react';

export const GridHeader = styled.div`
	font-size: 20px;
	letter-spacing: -0.4px;
	margin-bottom: 40px;
`;

export const GridGroup: React.FC<PropsWithChildren> = ({ children }) => {
	return <div>{children}</div>;
};
