import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from './Button';
import { InputField, SelectField, TextAreaField } from './Input';
import { Formik, FormikProps } from 'formik';
import { createOffer, updateOffer, updateOfferUploads } from '@/services/Offer';
import {
	Category,
	CreateOfferRequest,
	CreateOfferRequestGradeEnum,
	Enclosure,
	LookupApi,
	Offer,
	OfferMedia,
} from '@smartswap/client-api';
import { ItemGradeDescriptions } from '@/ItemGrades';
import { Dialog } from './Dialog';
import { DialogContext } from '@/contexts/DialogContext';
import { config } from '@/configuration';
import { ImageRemoved, ImageUpload } from './ImageUpload';
import { useNavigate } from 'react-router-dom';
import { Row } from './Grid';
import * as Yup from 'yup';
import { uploadImage } from '@/services/MediaService';
import { imgSrcToBlob } from '@/lib/images';
import { useTranslation } from 'react-i18next';
import { usePersonalAddresses } from '@/hooks/api/usePersonalAddresses';
import { AddAddressDialog } from './Address/AddAddressDialog';
import { TFunction } from 'i18next';
import { useQueryClient } from 'react-query';

const Wrapper = styled.div`
	height: 100%;
	width: 100%;

	form {
		width: 100%;
		max-width: 768px;
	}
`;

const Columns = styled.div`
	display: flex;
	flex-flow: row;

	& > *:not(:last-child) {
		margin-right: var(--step);
	}
`;

const StyledForm = styled.form`
	font-family: 'InterUI';
	display: flex;
	flex-flow: column;
`;

let uploadList = [] as Array<string>;
function renderEnclosureOptionString(enclosure: Enclosure, t: TFunction) {
	if (enclosure.slug === 'oversize') {
		return t(`sizes.${enclosure.slug}`);
	}

	return `${t(`sizes.${enclosure.slug}`)} (${enclosure.width}×${enclosure.height}×${enclosure.depth} cm)`;
}

const CreateOfferForm = ({
	handleSubmit,
	setFieldValue,
	values,
	isSubmitting,
	initialValues,
}: FormikProps<CreateOfferRequest & { media: OfferMedia[]; isUpdating: boolean }>) => {
	const { popDialog, showDialog } = useContext(DialogContext);
	const [enclosures, setEnclosures] = useState<Enclosure[]>([]);
	const [categories, setCategories] = useState<Category[]>([]);
	const [uploadUUIDs, setUploadUUIDs] = useState<string[]>(initialValues.uploadUuids);
	const [uploadingCount, setUploadingCount] = useState<number>(0);
	const [initialBlobs, setInitialBlobs] = useState<Blob[]>([]);
	const { addresses, update: refreshAddresses } = usePersonalAddresses();
	const { t } = useTranslation(['add-item', 'categories']);

	useEffect(() => {}, [enclosures, categories, uploadUUIDs, uploadingCount, initialBlobs, addresses, t]);

	useEffect(() => {
		Promise.all(initialValues.media.map((media) => imgSrcToBlob(media.src))).then(setInitialBlobs);
	}, [initialValues]);

	useEffect(() => {
		let lookupApi = new LookupApi(config);
		lookupApi.lookupEnclosuresGet().then((response) => setEnclosures(response.enclosures || []));
		lookupApi.lookupCategoriesGet().then((response) => setCategories(response.categories || []));
	}, []);

	useEffect(() => {
		setFieldValue('uploadUuids', uploadUUIDs);
	}, [uploadUUIDs, setFieldValue]);

	const increaseUploadCount = useCallback(() => {
		setUploadingCount((count) => count + 1);
	}, [setUploadingCount]);

	const decreaseUploadCount = useCallback(() => {
		setUploadingCount((count) => count - 1);
	}, [setUploadingCount]);

	const handleAddressAdded = useCallback(() => {
		popDialog();
		refreshAddresses();
	}, [popDialog, refreshAddresses]);

	uploadList = uploadUUIDs;

	const onAddImage = useCallback(
		(image: Blob) => {
			increaseUploadCount();

			uploadImage(image)
				.then(async (upload) => {
					const response = await upload.json();
					uploadList.push(response.uuid);
					setUploadUUIDs([...[], ...uploadList]);
				})
				.catch((err) => {
					console.error('Upload failed:', err);
				})
				.finally(decreaseUploadCount);
		},
		[increaseUploadCount, decreaseUploadCount, setUploadUUIDs],
	);

	const onRemoveImage = useCallback(
		({ indexRemoved }: ImageRemoved) => {
			const newUUIDs = uploadUUIDs.filter((id, index) => index !== indexRemoved); // splice is not working with immutables
			setUploadUUIDs(newUUIDs);
			uploadList = newUUIDs;
		},
		[setUploadUUIDs, uploadUUIDs],
	);

	return (
		<StyledForm onSubmit={handleSubmit}>
			<InputField type={'text'} name={'title'} label={t('form.itemTitle')} />
			<SelectField name={'categorySlug'} placeholder={t('form.selectCategory')} label={t('form.category')}>
				<option value={''} disabled={true}>
					{t('form.selectCategory')}
				</option>
				{categories.map((category) => (
					<option key={category.slug} value={category.slug}>
						{t(category.slug, { ns: 'categories' })}
					</option>
				))}
			</SelectField>
			<SelectField name={'grade'} placeholder={t('form.selectCondition')} label={t('form.condition')}>
				<option value={''} disabled={true}>
					{t('form.selectCondition')}
				</option>
				{ItemGradeDescriptions.map((grade) => (
					<option key={grade.value} value={grade.value}>
						{t(`conditions.${grade.description}`, { ns: 'common' })}
					</option>
				))}
			</SelectField>
			<SelectField name={'enclosureSlug'} placeholder={t('form.selectEnclosure')} label={t('form.enclosure')}>
				<option value={''} disabled={true}>
					{t('form.selectEnclosure')}
				</option>
				{enclosures.map((enclosure) => (
					<option key={enclosure.slug} value={enclosure.slug}>
						{renderEnclosureOptionString(enclosure, t)}
					</option>
				))}
			</SelectField>

			{values.enclosureSlug === 'oversize' && (
				<>
					<Columns>
						<InputField type={'number'} name={'dimensions.width'} label={t('form.itemWidth')} />
						<InputField type={'number'} name={'dimensions.height'} label={t('form.itemHeight')} />
						<InputField type={'number'} name={'dimensions.depth'} label={t('form.itemDepth')} />
						<InputField type={'number'} name={'dimensions.weight'} label={t('form.itemWeight')} />
					</Columns>
					<SelectField name={'addressId'} placeholder={t('form.selectAddress')} label={t('form.address')}>
						<option value={''} disabled={true}>
							{t('form.selectAddress')}
						</option>
						{addresses.map((address) => (
							<option key={address.id} value={address.id}>
								{address.title}
							</option>
						))}
					</SelectField>
					<Button
						type={'button'}
						onClick={() => {
							showDialog(<AddAddressDialog onComplete={handleAddressAdded} />);
						}}
					>
						{t('form.addNewAddress')}
					</Button>
				</>
			)}

			<TextAreaField rows={6} name={'description'} />
			<ImageUpload maxImages={3} onAdd={onAddImage} onRemove={onRemoveImage} initialBlobs={initialBlobs} />
			<Row style={{ marginTop: '16px' }}>
				{isSubmitting || uploadingCount > 0 ? (
					<Button disabled={true} type={'button'}>
						{t('form.pleaseWait')}
					</Button>
				) : uploadUUIDs.length === 0 ? (
					<Button disabled={true} type={'button'}>
						{t('form.addMinImage', { count: 1 })}
					</Button>
				) : (
					<Button type={'submit'}>
						{initialValues.isUpdating ? t('form.updateItem') : t('form.postItem')}
					</Button>
				)}
				<Button $variant={'outline'} type={'button'} onClick={popDialog}>
					{t('form.cancel')}
				</Button>
			</Row>
		</StyledForm>
	);
};

interface CreateOfferDialogProps {
	offer?: Offer;
	onEdit?: (id: string) => void;
}

export const CreateOfferDialog: React.FC<CreateOfferDialogProps> = (props) => {
	const { popDialog } = useContext(DialogContext);
	const { offer } = props;
	const navigate = useNavigate();
	const { t } = useTranslation('add-item');
	const queryClient = useQueryClient();

	async function submitForm(newOffer: CreateOfferRequest) {
		if (offer) {
			await updateOffer(offer.id, newOffer as any).then(() => {
				updateOfferUploads(offer.id, newOffer.uploadUuids);
			});
			props.onEdit?.(offer.id);
			popDialog();
		} else {
			const createdOffer = await createOffer(newOffer);
			await queryClient.invalidateQueries('offers');
			popDialog();
			navigate('/profile/giveaway?highlight=' + createdOffer.id, { replace: true });
		}
	}

	const validationSchema = Yup.object().shape({
		title: Yup.string()
			.min(3, t('validation.characterCountRequired', { count: 3 }))
			.required(t('validation.isRequired', { name: 'title' })),
		categorySlug: Yup.string().required(t('validation.isRequired', { name: 'category' })),
		grade: Yup.string().required(t('validation.isRequired', { name: 'condition' })),
		enclosureSlug: Yup.string().required(t('validation.isRequired', { name: 'enclosure' })),
		// dimensions: Yup.object().when('enclosureSlug', {
		//  	is: 'oversize',
		//  	then: Yup.object().shape({
		//  		width: Yup.number()
		//  			.min(1, t('validation.valueGreater', { name: 'width', value: 1 }))
		//  			.required(t('validation.isRequired', { name: 'width' })),
		//  		height: Yup.number()
		//  			.min(1, t('validation.valueGreater', { name: 'height', value: 1 }))
		//  			.required(t('validation.isRequired', { name: 'height' })),
		//  		depth: Yup.number()
		//  			.min(1, t('validation.valueGreater', { name: 'depth', value: 1 }))
		// 			.required(t('validation.isRequired', { name: 'depth' })),
		//  		weight: Yup.number()
		//  			.min(1, t('validation.valueGreater', { name: 'weight', value: 1 }))
		//  			.required(t('validation.isRequired', { name: 'weight' })),
		//  	}),
		//  }),
		//  addressId: Yup.string().when('enclosureSlug', {
		//  	is: 'oversize',
		//  	then: Yup.string().required(t('validation.isRequired', { name: 'address' })),
		//  }),
	});

	const initialValues: CreateOfferRequest & { media: OfferMedia[]; isUpdating: boolean } = {
		categorySlug: offer?.details.category.slug || '',
		description: offer?.details.description || '',
		enclosureSlug: offer?.details.enclosure.slug || '',
		grade: CreateOfferRequestGradeEnum.A || '',
		title: offer?.details.title || '',
		uploadUuids: offer?.media?.map((i) => i.uuid) || [],
		media: offer?.media || [],
		isUpdating: !!offer,
		addressId: offer?.details.addressId || '',
		dimensions: offer?.details.dimensions,
	};

	const title = offer?.details.title ? t('editItem', { title: offer.details.title }) : t('addNewItem');

	return (
		<Dialog title={title}>
			<Wrapper>
				<Formik
					validationSchema={validationSchema}
					initialValues={initialValues}
					onSubmit={submitForm}
					component={CreateOfferForm}
				/>
			</Wrapper>
		</Dialog>
	);
};
